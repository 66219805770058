<script>
import formVal from '@/plugins/form-validator';

export default {
  name: 'OEMUserEditPage',
  data() {
    return {
      selected_user: null,
      today: null,
      tomorrow: null,
      user: {
        name: null,
        company: null,
        email: null,
        type: null,
        emailVerified: null,
        loading: true,
        password: '',
        password_valid: null,
      },
      user_types: [
        { text: 'Normal User', value: 'USER' },
        { text: 'OEM Admin', value: 'OEM' },
      ],
      loggers: {
        owned: [],
        accessible: [],
      },
      loggerTableFields: [
        {
          key: 'serial',
          label: 'Serial Number',
          sortable: false,
        },
        {
          key: 'owned',
          label: 'Access Type',
          sortable: false,
          formatter: v => (v ? 'Owner' : 'Accessor'),
        },
        {
          key: 'id',
          label: '',
          sortable: false,
        },
      ],
      userSub: {
        active: null,
        wasActive: null,
        numLoggers: null,
        quotaPerLogger: null,
        quotaMultiplier: 1000000,
        start: null,
        end: null,
      },
      quotaMultipliers: [
        { value: 1, text: 'Bytes' },
        { value: 1000, text: 'kB' },
        { value: 1000000, text: 'MB' },
        { value: 1000000000, text: 'GB' },
      ],
      loggerSubs: [],
      loggerSubFields: [
        {
          key: 'serial',
          label: 'Serial Number',
          sortable: true,
        },
        {
          key: 'quota',
          label: 'Quota',
          sortable: true,
          formatter: val => this.formatQuota(val),
        },
        {
          key: 'start',
          label: 'Start',
          formatter: val => this.$d(new Date(val)),
        },
        {
          key: 'end',
          label: 'End',
          formatter: val => (val !== null && this.$d(new Date(val))) || 'Infinite',
        },
        {
          key: 'id',
          label: '',
        },
      ],
      loggerSubEdit: {
        id: null,
        serial: null,
        quota: null,
        quotaMultiplier: null,
        start: null,
        end: null,
      },
      loggerSubErrors: {
        serial: null,
      },
      deletion: {
        hasNoLoggers: null,
        email: '',
        emailConfirmation: '',
      },
      error: null,
    };
  },
  computed: {
    uSubQuotaDisabled() {
      return !this.userSub.active || this.userSub.quotaPerLogger === null;
    },
    uSubQuotaInfinite() {
      return this.userSub.active && this.userSub.quotaPerLogger === null;
    },
    lSubQuotaInfinite() {
      return this.loggerSubEdit.quota === null;
    },
    allUserLoggers() {
      let ownedLoggers = this.loggers.owned;
      ownedLoggers = ownedLoggers.map(x => ({ ...x, owned: true }));

      let accessibleLoggers = this.loggers.accessible;
      accessibleLoggers = accessibleLoggers.map(x => ({ ...x, owned: false }));

      return [...ownedLoggers, ...accessibleLoggers];
    },
  },
  /**
   * Runs on Page Load
   */
  mounted() {
    this.$Progress.start(); // Show a loading bar
    this.selected_user = this.$route.params.uid;
    // Get the user's details
    const prDetails = this.$http({
      url: `/oem/user/${this.$route.params.uid}`,
      method: 'GET',
    })
      .then((resp) => {
        this.user.name = resp.data.name;
        this.user.company = resp.data.company;
        this.user.email = resp.data.email;
        this.deletion.email = resp.data.email;
        this.user.type = resp.data.accessLevel;
        this.user.emailVerified = resp.data.emailVerified;
        this.user.loading = false;
        this.$route.meta.breadcrumbs[3].text = this.user.name;
      });
    // Get the user subscription
    const prUserSub = this.$http({
      url: `/oem/user/${this.$route.params.uid}/user-subscription`,
      method: 'GET',
    })
      .then((resp) => {
        if (resp.status === 204) {
          this.userSub.active = false;
          this.userSub.wasActive = false;
          this.userSub.start = new Date().toISOString().substring(0, 10);
        } else {
          this.userSub.active = true;
          this.userSub.wasActive = true;
          this.userSub.numLoggers = resp.data.numLoggers;
          this.userSub.start = resp.data.start.substring(0, 10);
          this.userSub.end = resp.data.end ? resp.data.end.substring(0, 10) : null;
          // Setup quota multiplier
          let quota = resp.data.quotaPerLogger;
          let quotaMultiplier = 1;
          while (quota > 1000 && Number.isInteger(quota / 1000)) {
            quotaMultiplier *= 1000;
            quota /= 1000;
          }
          this.userSub.quotaPerLogger = quota;
          this.userSub.quotaMultiplier = quotaMultiplier;
        }
      });
    // Get all the logger subscriptions from the server
    const prLoggerSub = this.refreshLoggerSubscriptions();
    // Get all the loggers this account can access
    const prUserLoggers = this.$http({
      method: 'GET',
      url: `/oem/user/${this.selected_user}/loggers`,
    })
      .then((resp) => {
        this.loggers.owned = resp.data.owned;
        this.loggers.accessible = resp.data.accessible;
      });

    // Runs once all requests have completed
    Promise.all([prDetails, prUserSub, prLoggerSub, prUserLoggers])
      .then(() => this.$Progress.finish())
      .catch((err) => {
        this.$Progress.fail();
        if (err.response.status === 404) {
          this.error = 'User not found';
        } else {
          this.error = true;
        }
      });
    // Create references for today and tomorrow for date pickers
    const now = new Date();
    this.today = now.toISOString().substring(0, 10);
    now.setDate(now.getDate() + 1);
    this.tomorrow = now.toISOString().substring(0, 10);
  },
  methods: {
    /**
     * Refresh the logger subscription list from the server
     */
    refreshLoggerSubscriptions() {
      return this.$http({
        url: `/oem/user/${this.$route.params.uid}/logger-subscriptions`,
        method: 'GET',
      })
        .then((resp) => {
          this.loggerSubs = resp.data.map((sub) => {
            const newSub = { ...sub };
            newSub.start = new Date(sub.start);
            newSub.end = sub.end ? new Date(sub.end) : null;
            return newSub;
          });
        });
    },

    /**
     * Reset the currently selected user's password with the one in the text box
     */
    resetUserPassword() {
      this.$bvModal.msgBoxConfirm("Are you sure you want to set this user's password?")
        .then((confirm) => {
          if (confirm) {
            this.$http({
              url: `/oem/user/${this.selected_user}/passwd`,
              method: 'PATCH',
              data: { password: this.user.password },
            })
              .then(() => {
                this.user.password = '';
                this.user.password_valid = null;
                this.$bvToast.toast('Password Changed Successfully', {
                  title: 'Password Changed',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                  solid: true,
                });
              })
              .catch((err) => {
                if (err.response.status === 422) {
                  this.user.password_valid = false;
                }
              });
          }
        });
    },
    /**
     * Submit the updated user info
     */
    updateUserInfo(evt) {
      evt.preventDefault();
      this.$http({
        url: `/oem/user/${this.selected_user}`,
        method: 'PUT',
        data: {
          name: this.user.name,
          company: this.user.company,
          email: this.user.email,
          emailVerified: this.user.emailVerified,
          type: this.user.type,
        },
      })
        .then(() => {
          this.$bvToast.toast('User Updated Successfully', {
            title: 'Success',
            variant: 'success',
            toaster: 'b-toaster-bottom-center',
            solid: true,
          });
        });
    },
    /**
     * Handle User Subscription numLoggers checkbox behaviour
     */
    numLoggersChecked() {
      if (this.userSub.numLoggers !== null) {
        this.userSub.numLoggers = null;
      } else {
        this.userSub.numLoggers = 1;
      }
    },
    /**
     * Handle User Subscription quota checkbox behaviour
     */
    quotaPerLoggerChecked() {
      if (this.userSub.quotaPerLogger !== null) {
        this.userSub.quotaPerLogger = null;
      } else {
        this.userSub.quotaPerLogger = 1;
      }
    },
    /**
     * Handle User Subscription end date checkbox behaviour
     */
    userSubEndDateChecked() {
      if (this.userSub.end !== null) {
        this.userSub.end = null;
      } else {
        this.userSub.end = this.tomorrow;
      }
    },
    /**
     * Save the updated user subscription to the server
     */
    updateUserSubscription() {
      let requestPromise;
      if (!this.userSub.active && this.userSub.wasActive) {
        requestPromise = this.$http({
          url: `/oem/user/${this.$route.params.uid}/user-subscription`,
          method: 'DELETE',
        });
      } else if (this.userSub.active) {
        requestPromise = this.$http({
          url: `/oem/user/${this.$route.params.uid}/user-subscription`,
          method: 'PUT',
          data: {
            numLoggers: Number.parseInt(this.userSub.numLoggers, 10),
            quotaPerLogger: (this.userSub.quotaPerLogger * this.userSub.quotaMultiplier) || null,
            start: new Date(this.userSub.start),
            end: this.userSub.end ? new Date(this.userSub.end) : null,
          },
        });
      } else return;
      requestPromise
        .then(() => {
          this.$bvToast.toast('Subscription updated successfully', {
            title: 'Success',
            variant: 'success',
            toaster: 'b-toaster-bottom-center',
          });
        });
    },
    /**
     * Format a value in bytes to a human readable string
     */
    formatQuota(bytes) {
      if (bytes === null) {
        return 'Unlimited';
      }
      if (bytes < 1024) {
        return `${bytes} bytes`;
      }
      if (bytes < 1024000) {
        return `${(bytes / 1000).toFixed(2)} kB`;
      }
      if (bytes < 1024000000) {
        return `${(bytes / 1000000).toFixed(2)} MB`;
      }
      return `${(bytes / 1000000000).toFixed(2)} GB`;
    },
    /**
     * Show the add logger subscription dialog
     */
    addLoggerSub() {
      this.loggerSubEdit = {
        id: null,
        serial: '',
        quota: null,
        quotaMultiplier: 1000,
        start: this.today,
        end: null,
      };
      this.$bvModal.show('oem-lSub-edit');
    },
    /**
     * Show the edit dialog with the correct values
     */
    editLoggerSub(id) {
      this.loggerSubEdit = { ...this.loggerSubs.find(x => x.id === id) };
      // Compute the quota multiplier
      this.loggerSubEdit.quotaMultiplier = 1;
      while (this.loggerSubEdit.quota > 1000 && Number.isInteger(this.loggerSubEdit.quota / 1000)) {
        this.loggerSubEdit.quotaMultiplier *= 1000;
        this.loggerSubEdit.quota /= 1000;
      }
      // Reformat the dates to work with HTML forms
      this.loggerSubEdit.start = this.loggerSubEdit.start.toISOString().substring(0, 10);
      if (this.loggerSubEdit.end) {
        this.loggerSubEdit.end = this.loggerSubEdit.end.toISOString().substring(0, 10);
      }
      this.$bvModal.show('oem-lSub-edit');
    },
    /**
     * Handle Logger Subscription quota checkbox behaviour
     */
    quotaChecked() {
      if (this.loggerSubEdit.quota !== null) {
        this.loggerSubEdit.quota = null;
      } else {
        this.loggerSubEdit.quota = 1;
      }
    },
    /**
     * Handle Logger Subscription end field checkbox behaviour
     */
    loggerSubEndChecked() {
      if (this.loggerSubEdit.end !== null) {
        this.loggerSubEdit.end = null;
      } else {
        this.loggerSubEdit.end = this.tomorrow;
      }
    },
    /**
     * Handle the 'ok' button on the logger sub form
     */
    loggerSubSubmit() {
      // Perform form validation
      if (
        formVal.validate.single(this.loggerSubEdit.serial, { presence: true, loggerSerial: true })
      ) {
        this.loggerSubErrors.serial = false;
        return;
      }

      // Reset the error if we passed
      this.loggerSubErrors.serial = null;

      // Now post the data to the server
      this.$Progress.start();
      this.$http({
        method: 'POST',
        url: `/oem/user/${this.selected_user}/logger-subscriptions`,
        data: {
          serial: this.loggerSubEdit.serial,
          quota: this.loggerSubEdit.quota,
          start: this.loggerSubEdit.start,
          end: this.loggerSubEdit.end,
        },
      })
        .then(() => {
          this.refreshLoggerSubscriptions()
            .then(() => {
              this.$Progress.finish();
              this.$bvModal.hide('oem-lSub-edit');
            })
            .catch(() => this.$Promise.fail());
        })
        .catch(() => {
          this.$bvToast.toast('Failed to add logger subscription', {
            variant: 'danger',
            title: 'Failure',
          });
          this.$Progress.fail();
        });
    },

    /**
     * Delete a logger subscription
     */
    loggerSubDel(subId) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this logger subscription?')
        .then((resp) => {
          if (resp) {
            this.$http({
              method: 'DELETE',
              url: `/oem/user/${this.selected_user}/logger-subscriptions/${subId}`,
            })
              .then(() => {
                this.$Progress.start();
                this.refreshLoggerSubscriptions()
                  .then(() => this.$Progress.finish())
                  .catch(() => this.$Progress.fail());
              })
              .catch(() => {
                this.$bvToast.toast('Failed to delete logger subscription', {
                  title: 'Failure',
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-center',
                });
              });
          }
        });
    },

    /**
     * When the delete modal opens, check the conditions for deletion are satisfied
     */
    checkDeletionConditions() {
      // Check whether the prerequisites for deletion have been met
      this.deletion.hasNoLoggers = (this.loggers.owned.length === 0);
    },

    /**
     * Called to actually perform the deletion of the user
     */
    deleteUser() {
      // Check the confirmation email is correct
      if (this.deletion.email === this.deletion.emailConfirmation) {
        this.$http({
          method: 'DELETE',
          url: `/oem/user/${this.selected_user}`,
          data: {
            email: this.deletion.emailConfirmation,
          },
        })
          .then(() => {
            // The deletion succeeded. Show a message for 2 seconds
            this.$bvToast.toast('The user was successfully deleted', {
              title: 'Success',
              toaster: 'b-toaster-bottom-center',
              variant: 'success',
              noAutoHide: true,
              noCloseButton: true,
            });

            // Then push the user back
            setTimeout(() => this.$router.go(-1), 2000);
          })
          .catch(() => {
            // Something failed
            this.$bvToast.toast('Failed to Delete User', {
              title: 'An Error Occurred',
              toaster: 'b-toaster-bottom-center',
              variant: 'danger',
            });
          });
      }
    },

    /**
     * Hide and reset the user deletion dialog
     */
    hideDeleteDialog() {
      this.$refs['deletion-modal'].hide();
      this.deletion.emailConfirmation = '';
    },
  },
};
</script>

<template>
  <b-container>
    <!-- Below here is displayed when there is no error -->
    <b-row v-if="!this.error" align-h="center">
      <b-col>

        <!-- Begin User Details Card -->
        <b-card title='User Details' class='mb-4'>
          <b-form>

            <!-- Name Edit Box -->
            <b-form-group label='Name'>
              <b-input v-bind:disabled='!selected_user' type='text' v-model="user.name"/>
            </b-form-group>

            <!-- Company Edit Box -->
            <b-form-group label='Company'>
              <b-input v-bind:disabled='!selected_user' type='text' v-model="user.company"/>
            </b-form-group>

            <!-- Email edit box and verified checkbox -->
            <b-form-group label='Email'>
              <b-input v-bind:disabled='!selected_user' type='email' v-model="user.email"/>
            </b-form-group>
            <b-form-checkbox class='mb-4' v-model='user.emailVerified' switch>
              Email Verified
            </b-form-checkbox>

            <!-- Password edit box -->
            <b-form-group label='Password'>
              <b-input-group >
                <b-form-input type='password'
                              placeholder='Set using button to right'
                              v-bind:disabled='!selected_user'
                              v-model='user.password'
                              v-bind:state='user.password_valid'/>
                <b-input-group-append>
                  <b-btn variant='danger'
                        v-bind:disabled='!selected_user || user.password.length === 0'
                        @click='resetUserPassword()'>Set</b-btn>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback v-bind:state='user.password_valid'>
                Password must be at least 8 characters long and
                contain both upper and lowercase characters, and a number.
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- User Type Selector -->
            <b-form-radio-group
              class='mb-1'
              v-model="user.type"
              :options="user_types"
              v-bind:disabled='!selected_user'
            ></b-form-radio-group>

            <!-- Save button -->
            <b-btn @click='updateUserInfo' class='mt-2'>Save</b-btn>

          </b-form>
        </b-card>
        <!-- End User Details Card -->

        <!-- Begin user loggers card -->
        <b-card title='Accessible Loggers' class='mb-4'>
          <b-table :items='allUserLoggers'
                   :fields='loggerTableFields'>

            <!-- Render the 'id' field as a button -->
            <template v-slot:cell(id)="data">
              <b-btn variant='secondary' :to='`/oem/logger/${data.item.id}`' class='float-right'>
                Go to logger admin page <fa-icon icon='arrow-right' />
              </b-btn>
            </template>

          </b-table>
        </b-card>
        <!-- End user loggers card -->

        <!-- Begin User Subscription Card -->
        <b-card title='User Subscription' class='mb-4'>
          <b-form>

            <!-- Title Row -->
            <b-row>
              <b-col>
                <b-form-checkbox v-model='userSub.active' switch>
                  Apply a User Subscription
                </b-form-checkbox>
              </b-col>
            </b-row>

            <!-- Number of Loggers Edit Box -->
            <b-row class='mt-4'>
              <b-col cols=4>
                <label for='oem-user-edit-usub-numLoggers'>
                  Number of Loggers
                </label>
              </b-col>
              <b-col cols=8>
                <b-input-group>
                  <b-input id='oem-user-edit-usub-numLoggers'
                          type='number'
                          min=1
                          v-model='userSub.numLoggers'
                          v-bind:disabled='!userSub.active || userSub.numLoggers === null' />
                  <b-input-group-append is-text>
                    <b-form-checkbox v-bind:checked='userSub.active && userSub.numLoggers === null'
                                     @change='numLoggersChecked()'>
                      Unlimited
                    </b-form-checkbox>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- Quota per logger edit box -->
            <b-row class='mt-4'>
              <b-col cols=4>
                <label for='oem-user-edit-usub-quota'>
                  Quota Per Logger
                </label>
              </b-col>
              <b-col cols=8>
                <b-input-group>
                  <b-input id='oem-user-edit-usub-quota'
                          type='number'
                          min=1
                          v-model='userSub.quotaPerLogger'
                          v-bind:disabled='uSubQuotaDisabled' />
                  <b-form-select v-model='userSub.quotaMultiplier'
                                 :options='quotaMultipliers'
                                 v-bind:disabled='uSubQuotaDisabled'
                                 style='max-width: 6em'
                                 required />
                  <b-input-group-append is-text>
                    <b-form-checkbox v-bind:checked='uSubQuotaInfinite'
                                     @change='quotaPerLoggerChecked()'>
                      Unlimited
                    </b-form-checkbox>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- Start date edit box -->
            <b-row class='mt-4'>
              <b-col cols=4>
                <label for='oem-user-edit-usub-start'>
                  Start Date
                </label>
              </b-col>
              <b-col cols=8>
                <b-input-group>
                  <b-input id='oem-user-edit-usub-start'
                          type='date'
                          v-model='userSub.start'
                          v-bind:disabled='!userSub.active' />
                  <b-input-group-append>
                    <b-btn variant='accent'
                           @click='userSub.start = today'
                           v-bind:disabled='!userSub.active' >
                      Today
                    </b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- End date edit box -->
            <b-row class='mt-4'>
              <b-col cols=4>
                <label for='oem-user-edit-usub-end'>
                  End Date
                </label>
              </b-col>
              <b-col cols=8>
                <b-input-group>
                  <b-input id='oem-user-edit-usub-end'
                          type='date'
                          v-bind:min='userSub.start'
                          v-model='userSub.end'
                          v-bind:disabled='!userSub.active || userSub.end === null' />
                  <b-input-group-append is-text>
                    <b-form-checkbox v-bind:checked='userSub.active && userSub.end === null'
                                     @change='userSubEndDateChecked()'>
                      Infinite
                    </b-form-checkbox>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- Save button -->
            <b-btn @click='updateUserSubscription()' class='mt-2'>Save</b-btn>

          </b-form>
        </b-card>
        <!-- End User Subscription Card -->

        <!-- Begin Logger Subscription Card -->
        <b-card class='mb-4'>
          <!-- Card Title -->
          <h4>
            Logger Subscriptions
            <b-btn variant='success' class='mb-2 ml-2 float-right' @click='addLoggerSub()'>
              <fa-icon icon='plus' />
            </b-btn>
          </h4>

          <!-- Subscription Table -->
          <b-table :items='loggerSubs'
                   :fields='loggerSubFields'
                   sort-icon-left
                   show-empty >
            <!-- Empty table placeholder -->
            <template v-slot:empty>
              <p class='lead text-center'>No Logger Subscriptions Found</p>
            </template>
            <!-- Edit button rendering -->
            <template v-slot:cell(id)="data">
              <b-btn @click='editLoggerSub(data.value)' class='mr-2'>
                <fa-icon icon='edit' />
              </b-btn>
              <b-btn @click='loggerSubDel(data.value)' variant='danger'>
                <fa-icon icon='trash' />
              </b-btn>
            </template>
          </b-table>

        </b-card>
        <!-- End Logger Subscription Card -->

        <!-- Danger Controls below Here -->
        <b-card header='Danger Area'
                border-variant='danger'
                header-border-variant='danger'
                header-text-variant='danger'
                align='center'
                class='mb-4'>

          <b-row align-h='center'>
            <p> Delete this user and all their account data from the system.</p>
          </b-row>
          <b-row align-h='center'>
            <b-col cols='6'>
              <b-btn v-b-modal.oem-user-delete variant='danger' block>
                Delete User Account
              </b-btn>
            </b-col>
          </b-row>

        </b-card>

      </b-col>
    </b-row>
    <!-- End of error-free content -->

    <!-- Begin Error Messages Rendered Here -->
    <b-row align-h='center' v-else>
      <b-col cols=auto>
        <h2>An error occurred</h2>
        {{ error || 'Unknown Error' }}<br>
        <b-link @click='$router.go(-1)'>Click here to go back</b-link>
      </b-col>
    </b-row>
    <!-- End Error Messages Rendered Here -->

    <!-- Begin Logger Subscription Edit Dialog -->
    <b-modal id='oem-lSub-edit'
             title='Logger Subscription'
             size='lg'
             no-close-on-backdrop
             @ok.prevent='loggerSubSubmit' >

      <!-- Serial Number Input -->
      <b-row>
        <b-col cols=4>
          <label for='oem-user-edit-lSub-serial'>
            Serial Number
          </label>
        </b-col>
        <b-col cols=8>
          <b-form-group>
            <b-input id='oem-user-edit-lSub-serial'
                     v-model='loggerSubEdit.serial'
                     v-bind:state='loggerSubErrors.serial' />
            <b-form-invalid-feedback v-bind:state='loggerSubErrors.serial'>
              Serial number must be in the form PMXSXXXX
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Quota Input -->
      <b-row class='mt-4'>
        <b-col cols=4>
          <label for='oem-user-edit-lSub-quota'>
            Quota
          </label>
        </b-col>
        <b-col cols=8>
          <b-input-group>
            <b-input id='oem-user-edit-lSub-quota'
                    type='number'
                    min=1
                    v-model='loggerSubEdit.quota'
                    v-bind:disabled='lSubQuotaInfinite' />
            <b-form-select v-model='loggerSubEdit.quotaMultiplier'
                            :options='quotaMultipliers'
                            v-bind:disabled='lSubQuotaInfinite'
                            style='max-width: 6em'
                            required />
            <b-input-group-append is-text>
              <b-form-checkbox v-bind:checked='lSubQuotaInfinite'
                                @change='quotaChecked()'>
                Unlimited
              </b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- Start Date Input -->
      <b-row class='mt-4'>
        <b-col cols=4>
          <label for='oem-user-edit-lsub-start'>
            Start Date
          </label>
        </b-col>
        <b-col cols=8>
          <b-input-group>
            <b-input id='oem-user-edit-lsub-start'
                    type='date'
                    v-model='loggerSubEdit.start' />
            <b-input-group-append>
              <b-btn variant='accent'
                     @click='loggerSubEdit.start = today' >
                Today
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- End Date Input -->
      <b-row class='mt-4'>
        <b-col cols=4>
          <label for='oem-user-edit-lsub-end'>
            End Date
          </label>
        </b-col>
        <b-col cols=8>
          <b-input-group>
            <b-input id='oem-user-edit-lsub-end'
                    type='date'
                    v-bind:min='loggerSubEdit.start'
                    v-model='loggerSubEdit.end'
                    v-bind:disabled='loggerSubEdit.end === null' />
            <b-input-group-append is-text>
              <b-form-checkbox v-bind:checked='loggerSubEdit.end === null'
                                @change='loggerSubEndDateChecked()'>
                Infinite
              </b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

    </b-modal>
    <!-- End Logger Subscription Edit Dialog -->

    <!-- Begin User Delete Dialog -->
    <b-modal id='oem-user-delete'
             ref='deletion-modal'
             title='Delete User'
             size='lg'
             @show='checkDeletionConditions()'
             @close="deletion.emailConfirmation = ''">

      <p>
        Are you sure you want to delete this user?
        This action is irreversible!
      </p>

      <p>Prerequisites: </p>
      <ul v-bind:class="deletion.hasNoLoggers ? 'text-success' : 'text-danger'">
        <li>
          User is not the owner of any logger
          <fa-icon v-if='deletion.hasNoLoggers == true' icon='check' class='ml-1'/>
          <fa-icon v-else-if='deletion.hasNoLoggers === false' icon='times' class='ml-1'/>
          <b-spinner v-else small variant='primary' />
        </li>
      </ul>

      <p v-if='deletion.hasNoLoggers === false' class='text-danger'>
        Please resolve the prerequisites before deleting
      </p>

      <template slot='modal-footer'>
        <b-input-group>
          <b-form-input placeholder='Type user email address to confirm'
                        v-model="deletion.emailConfirmation"
                        v-bind:disabled='deletion.hasNoLoggers !== true'/>
          <b-input-group-append>
            <b-btn variant='danger'
                   v-bind:disabled="deletion.emailConfirmation !== deletion.email"
                   @click='deleteUser()'>
              DELETE USER
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </template>

    </b-modal>
    <!-- End User Delete Dialog -->

  </b-container>
</template>
